import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "abbreviations-and-acronyms"
    }}>{`Abbreviations and Acronyms`}</h1>
    <p>{`Workday's Marketing guidelines state that, on the first mention of a product, writers should use the
full, correct product name, followed by an abbreviation or acronym in parentheses (if you plan to
refer to the product by its abbreviation later).`}</p>
    <p>{`However, Workday's user interface has many entry points, and it's not always possible to tell when a
user might encounter the name of a product for the first time. In UI text, we recommend using an
abbreviation or acronym if you you feel a user will already be familiar with a term or product name.`}</p>
    <p>{`If you think the user may be unfamiliar with a product name`}{`—`}{`for example, if it is a new
product or one the user encounters rarely`}{`—`}{`then follow the marketing guidelines above.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Workday HCM`}</li>
        <li parentName="ul">{`Workday Human Capital Management (Workday HCM)`}</li>
        <li parentName="ul">{`Service-level agreements (SLAs) can be complicated.`}</li>
      </ul>
    </Do>
    <p>{`See also: `}<a parentName="p" {...{
        "href": "/guidelines/content/language-and-style/product-references"
      }}>{`Product References`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      